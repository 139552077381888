// App.js
import React from 'react';
import Header from './components/Header';
import Hero from './components/Hero';
import Features from './components/Features';
import About from './components/About';
import Download from './components/Download';
import UsefulArticles from './components/UsefulArticles';
import Footer from './components/Footer';
import HowToUse from './components/HowToUse';
import HowItWorks from './components/HowItWorks'; 

function App() {
  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-800 to-gray-900 text-white">
      <Header />
      <main>
        <Hero />
        <HowItWorks />
        <Features /> 
        <HowToUse/>
        <About />
        <UsefulArticles />
        <Download />
      </main>
      <Footer />
    </div>
  );
}

export default App;