// components/Footer.js
import React from 'react';

const Footer = () => {
  return (
    <footer className="bg-gray-900/80 border-t border-gray-800 py-8">
      <div className="container mx-auto px-4">
        <div className="flex flex-col md:flex-row justify-between items-center">
          <div className="mb-4 md:mb-0">
            <img src="/doomscroll.png" alt="Logo" className="w-10 h-10 inline-block mr-2" />
            <span className="text-xl font-bold bg-gradient-to-r from-green-400 to-emerald-400 text-transparent bg-clip-text">
              Doomscroll Detector
            </span>
          </div>
          <nav className="mb-4 md:mb-0">
            <ul className="flex space-x-6">
              <li><a href="mailto:noah@ae.studio" className="text-gray-400 hover:text-green-400">Contact</a></li>
            </ul>
          </nav>
        </div>
      </div>
    </footer>
  );
};

export default Footer;