// components/Header.js
import React from 'react';
import { Button } from './ui/button';

const Header = () => {
  return (
    <header className="bg-gray-800/50 backdrop-blur-sm border-b border-gray-700">
      <div className="container mx-auto px-4 py-4 flex justify-between items-center">
        <div className="flex items-center space-x-2">
        <img src="/doomscroll.png" alt="Logo" className="w-10 h-10 inline-block mr-2" />
          <span className="text-xl font-bold bg-gradient-to-r from-green-400 to-emerald-400 text-transparent bg-clip-text">
              Doomscroll Detector
            </span>

        </div>
        <nav>
          <ul className="flex space-x-6">
          <li><a href="#how-it-works" className="text-gray-300 hover:text-green-400 transition-colors">How It Works</a></li>
            <li><a href="#features" className="text-gray-300 hover:text-green-400 transition-colors">Features</a></li>
            <li><a href="#how-to-use" className="text-gray-300 hover:text-green-400 transition-colors">Install</a></li>
            <li><a href="#about" className="text-gray-300 hover:text-green-400 transition-colors">About</a></li>
            <li><a href="#articles" className="text-gray-300 hover:text-green-400 transition-colors">Resources</a></li>
            <li><a href="#download" className="text-gray-300 hover:text-green-400 transition-colors">Download</a></li>
          </ul>
        </nav>
        <Button variant="outline" className="border-green-400 text-green-400 hover:bg-green-400/10">
          <a href="https://ae.studio" target="_blank" rel="noopener noreferrer">
            Contact Us
          </a>
        </Button>
      </div>
    </header>
  );
};

export default Header;