import React from 'react';

const HowToUse = () => {
  return (
    <section id="how-to-use" className="py-20 bg-gray-800/50">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold mb-12 text-center bg-gradient-to-r from-green-400 to-emerald-400 text-transparent bg-clip-text">
          How To Install The Extension
        </h2>
        <div className="max-w-8xl mx-auto">

          
          <div className="aspect-w-16 aspect-h-9">
            <center>
              <iframe width="640" height="414" src="https://www.loom.com/embed/f7c8432da80343b8be536b3751b1b8bb?sid=555c6e66-2c16-45fe-afde-7ef80d8b9600" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
            </center>
          </div>
          <p className="text-gray-300 mt-6 text-center">
            Watch this quick tutorial to learn how to make the most of Doomscroll Detector
          </p>
        </div>
          {/* Original video section */}

       
      </div>

    </section>
  );
};

export default HowToUse; 