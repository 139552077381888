import React from 'react';

const HowToUse = () => {
  return (
    <section id="how-to-use" className="py-20 bg-gray-800/50">
        <div className="container mx-auto px-4">
<h2 className="text-3xl font-bold mb-12 text-center bg-gradient-to-r from-green-400 to-emerald-400 text-transparent bg-clip-text">
How It Works
</h2>

<div className="flex justify-center gap-8 mb-8">
  <div className="w-1/2">
    <img 
      src="/redditdoomscroll.png" 
      alt="Description of first image"
      className="rounded-lg w-full h-auto"
    />
  </div>
  <div className="w-1/2">
    <img 
      src="/xdoomscroll.png" 
      alt="Description of second image"
      className="rounded-lg w-full h-auto"
    />
  </div>
</div>
<p className="text-gray-300 text-center mb-12">
  What you can expect to see the detector do. If you have been scrolling for long enough, you will see a friendly message to take a break, also summarizing the content you were reading into points to ponder. 
</p> 

</div>
    </section>
  );
};

export default HowToUse; 