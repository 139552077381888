import React from 'react';

const articles = [
  {
    title: "'Doomscrolling' is bad for your mental health. Do this instead",
    url: "https://www.today.com/health/how-stop-doomscrolling-its-affect-your-brain-t188725",
    source: "Today"
  },
  {
    title: "Everything you need to know about doomscrolling and how to avoid it",
    url: "https://health.clevelandclinic.org/everything-you-need-to-know-about-doomscrolling-and-how-to-avoid-it",
    source: "Cleveland Clinic"
  },
  {
    title: "You're doomscrolling again. Here's how to snap out of it",
    url: "https://www.nytimes.com/2020/07/15/technology/personaltech/youre-doomscrolling-again-heres-how-to-snap-out-of-it.html",
    source: "New York Times"
  }
];

const UsefulArticles = () => {
  return (
    <section id="articles" className="py-20 bg-gray-900/50">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold mb-12 text-center bg-gradient-to-r from-green-400 to-emerald-400 text-transparent bg-clip-text">
          Useful Articles
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 max-w-5xl mx-auto">
          {articles.map((article, index) => (
            <a
              key={index}
              href={article.url}
              className="bg-gray-800/50 p-6 rounded-lg border border-gray-700 backdrop-blur-sm hover:border-green-400 transition-colors"
            >
              <h3 className="text-xl font-semibold mb-2 text-gray-100">{article.title}</h3>
              <p className="text-gray-400">Source: {article.source}</p>
            </a>
          ))}
        </div>
      </div>
    </section>
  );
};

export default UsefulArticles; 