// components/Download.js
import React from 'react';
import { Button } from './ui/button';

const Download = () => {
  const handleDownload = () => {
    // Create a link element
    const link = document.createElement('a');
    link.href = '/extension.zip'; // The folder needs to be zipped
    link.download = 'extension.zip';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <section id="download" className="py-20 bg-gradient-to-br from-gray-800/90 to-gray-900/95 text-white border-y border-gray-800">
      <div className="container mx-auto px-4 text-center">
        <h2 className="text-3xl font-bold mb-6 bg-gradient-to-r from-green-400 to-emerald-400 text-transparent bg-clip-text">
          Ready to Take Control?
        </h2>
        <p className="text-xl mb-8 max-w-2xl mx-auto text-gray-300">
          Download Doomscroll Detector now and start your journey towards a healthier digital lifestyle.
        </p>
        <Button 
          size="lg" 
          className="bg-gradient-to-r from-green-500 to-emerald-500 hover:from-green-600 hover:to-emerald-600 text-white shadow-lg shadow-green-500/25"
          onClick={handleDownload}
        >
          Download for Chrome
        </Button>
        <p className="mt-4 text-sm text-gray-400">Compatible with Chrome version 88 and above</p>
      </div>
    </section>
  );
};

export default Download;