// components/About.js
import React from 'react';

const About = () => {
  return (
    <section id="about" className="py-20">
      <div className="container mx-auto px-4">
        <div className="flex flex-col items-center">
          <div className="max-w-4xl">
            <h2 className="text-3xl font-bold mb-6 text-white">Understanding Doomscrolling</h2>
            <p className="text-gray-300 mb-6">
              Doomscrolling, also known as doomsurfing, is the compulsive tendency to continuously scroll through and consume negative news or distressing content online, even when it causes anxiety or emotional distress. This modern phenomenon has become increasingly common with the rise of social media and 24/7 news cycles.
            </p>
            <p className="text-gray-300 mb-6">
              Many people find themselves caught in this cycle, spending hours consuming negative content about global events, disasters, political tensions, and social issues. While staying informed is important, excessive consumption of negative news can have serious impacts on mental health and daily life.
            </p>
            
            <h3 className="text-2xl font-semibold mb-4 text-white">Why Do We Doomscroll?</h3>
            <p className="text-gray-300 mb-6">
              This behavior is often driven by a combination of factors: our natural negativity bias, the desire to feel prepared for potential threats, and the addictive nature of social media platforms. The constant stream of updates creates a false sense of urgency and responsibility to stay informed.
            </p>

            <h3 className="text-2xl font-semibold mb-4 text-white">Benefits of Breaking Free</h3>
            <ul className="list-disc pl-5 text-gray-300 mb-6 space-y-2">
              <li>Reduced anxiety and stress levels</li>
              <li>Improved mental health and emotional well-being</li>
              <li>Better sleep quality and regular sleep patterns</li>
              <li>Increased daily productivity and focus</li>
              <li>More time for positive activities and relationships</li>
              <li>Enhanced ability to maintain perspective</li>
              <li>Greater control over information consumption</li>
              <li>Improved work-life balance</li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;