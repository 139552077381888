// components/Features.js
import React from 'react';
import { IoMdNotifications, IoMdAnalytics } from 'react-icons/io';

const features = [
  {
    icon: <IoMdNotifications className="w-12 h-12 text-green-600" />,
    title: 'Smart Alerts',
    description: 'Receive gentle reminders when youve been scrolling for too long.'
  },
  {
    icon: <IoMdAnalytics className="w-12 h-12 text-green-600" />,
    title: 'Insights',
    description: 'Get insights into what you scrolled past.'
  }
];

const Features = () => {
  return (
    <section id="features" className="py-20 bg-gray-800/50">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold mb-12 text-center bg-gradient-to-r from-green-400 to-emerald-400 text-transparent bg-clip-text">
          Key Features
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {features.map((feature, index) => (
            <div key={index} className="bg-gray-800/50 p-6 rounded-lg border border-gray-700 backdrop-blur-sm">
              <div className="mb-4 flex justify-center text-green-400">{feature.icon}</div>
              <h3 className="text-xl font-semibold mb-2 text-gray-100">{feature.title}</h3>
              <p className="text-gray-400">{feature.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Features;