// components/Hero.js
import React from 'react';
import { Button } from './ui/button';

const Hero = () => {
  const handleDownload = () => {
    // Create a link element
    const link = document.createElement('a');
    link.href = '/extension.zip'; // The folder needs to be zipped
    link.download = 'extension.zip';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <section className="py-20 text-center">
      <div className="container mx-auto px-4">
        <h1 className="text-5xl font-bold mb-6 text-white">Stop Doomscrolling, Start Living</h1>
        <p className="text-xl mb-8 text-gray-300 max-w-2xl mx-auto">
          Doomscroll Detector helps you break free from the endless cycle of negative news and regain control of your digital life.
        </p>
        <Button 
          size="lg" 
          className="bg-green-600 hover:bg-green-700 text-white"
          onClick={handleDownload}
        >
          Download Extension
        </Button>
      </div>
    </section>
  );
};

export default Hero;